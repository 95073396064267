/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body {
    /* font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal; */
}

.container {
    max-width: 1140px;
}

.nav-pills .nav-link {
    color: #2e5b7a;

    &:hover {
        background-color: #5884a1;
        color: white;
    }
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link,
.btn.btn-primary {
    background-color: #2e5b7a !important;
}

.btn.btn-primary:hover {
    background-color: #5884a1 !important;
    color: white;
}

.tooltip {
    .tooltip-inner {
        padding: 8px 12px;
        text-align: justify;
        font-size: small;
    }
}